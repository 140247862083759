import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DespreNoi from "./pages/DespreNoi";
import GazduireEcommerce from "./pages/GazduireEcommerce";
import GazduirePremium from "./pages/GazduirePremium";
import GazduireStandard from "./pages/GazduireStandard";
import GazduireWordpress from "./pages/GazduireWordpress";
import Homepage from "./pages/Homepage";
import Reseller from "./pages/Reseller";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";

const App = () => {
  return (
    <Router>
      <Navigation />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/despre-noi.html" element={<DespreNoi />} />
        <Route
          path="/gazduire-ecommerce.html"
          element={<GazduireEcommerce />}
        />
        <Route path="/gazduire-premium.html" element={<GazduirePremium />} />
        <Route path="/gazduire-web.html" element={<GazduireStandard />} />
        <Route
          path="/gazduire-wordpress.html"
          element={<GazduireWordpress />}
        />
        <Route path="/reseller.html" element={<Reseller />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
