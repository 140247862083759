import React, { useState } from "react";
import image3 from '../assets/images/3.png'

const Faqs = () => {
  const [activeId, setActiveId] = useState("accordion-collapse-body-1"); // State to track the active accordion item

  const toggleAccordion = (id) => {
    setActiveId(activeId === id ? "" : id); // Toggle the active accordion item
  };
  return (
    <div className="container relative md:mt-24 mt-16">
      <div className="grid grid-cols-1 pb-8 text-center">
        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-medium">
          Questions regarding Dedicated Server ?
        </h3>
        <p className="text-slate-400 max-w-xl mx-auto">
          Create, collaborate, and turn your ideas into incredible products with
          the definitive platform for digital design.
        </p>
      </div>

      <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-8 gap-[30px]">
        <div className="lg:col-span-6">
          <div className="lg:me-8">
            <img src={image3} alt={image3} />
          </div>
        </div>
        <div className="lg:col-span-6">
            {/*Accordion 1*/}
        <div className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden">
            <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
              <button
                type="button"
                className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeId === 'accordion-collapse-body-1' ? 'text-sky-500' : 'text-slate-800'}`}
                onClick={() => toggleAccordion('accordion-collapse-body-1')}
                aria-expanded={activeId === 'accordion-collapse-body-1'}
                aria-controls="accordion-collapse-body-1"
              >
                <span className="text-[17px]">How does it work ?</span>
                <svg
                  className={`w-4 h-4 shrink-0 transform transition-transform duration-500 ease-in-out ${activeId === 'accordion-collapse-body-1' ? 'rotate-180' : ''}`}
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                </svg>
              </button>
            </h2>
            <div
              id="accordion-collapse-body-1"
              className={`${activeId === 'accordion-collapse-body-1' ? '' : 'hidden'}`}
              aria-labelledby="accordion-collapse-heading-1"
            >
              <div className="p-5">
                <p className="text-slate-400 dark:text-gray-400">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.</p>
              </div>
            </div>
          </div>
            {/*Accordion 2*/}
        <div className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden">
            <h2 className="text-base font-semibold" id="accordion-collapse-heading-2">
              <button
                type="button"
                className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeId === 'accordion-collapse-body-2' ? 'text-sky-500' : 'text-slate-800'}`}
                onClick={() => toggleAccordion('accordion-collapse-body-2')}
                aria-expanded={activeId === 'accordion-collapse-body-2'}
                aria-controls="accordion-collapse-body-2"
              >
                <span className="text-[17px]">Do I need a designer?</span>
                <svg
                  className={`w-4 h-4 shrink-0 transform transition-transform duration-500 ease-in-out ${activeId === 'accordion-collapse-body-2' ? 'rotate-180' : ''}`}
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                </svg>
              </button>
            </h2>
            <div
              id="accordion-collapse-body-2"
              className={`${activeId === 'accordion-collapse-body-2' ? '' : 'hidden'}`}
              aria-labelledby="accordion-collapse-heading-2"
            >
              <div className="p-5">
                <p className="text-slate-400 dark:text-gray-400">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.</p>
              </div>
            </div>
          </div>
           {/*Accordion 3*/}
        <div className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden">
            <h2 className="text-base font-semibold" id="accordion-collapse-heading-3">
              <button
                type="button"
                className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeId === 'accordion-collapse-body-3' ? 'text-sky-500' : 'text-slate-800'}`}
                onClick={() => toggleAccordion('accordion-collapse-body-3')}
                aria-expanded={activeId === 'accordion-collapse-body-3'}
                aria-controls="accordion-collapse-body-3"
              >
                <span className="text-[17px]">How do I pay?</span>
                <svg
                  className={`w-4 h-4 shrink-0 transform transition-transform duration-500 ease-in-out ${activeId === 'accordion-collapse-body-3' ? 'rotate-180' : ''}`}
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                </svg>
              </button>
            </h2>
            <div
              id="accordion-collapse-body-3"
              className={`${activeId === 'accordion-collapse-body-3' ? '' : 'hidden'}`}
              aria-labelledby="accordion-collapse-heading-3"
            >
              <div className="p-5">
                <p className="text-slate-400 dark:text-gray-400">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.</p>
              </div>
            </div>
          </div>

            
          </div>
        </div>
      </div>
    
  );
};

export default Faqs;
