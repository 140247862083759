const siteMeta = {
  gazduireWebMeta: {
    title: "Gazduire Web Accesibila - Performanta Superioara",
    description:
      "Oferim solutii de gazduire web flexibile si accesibile, perfecte pentru site-uri personale sau de afaceri. Viteza si securitate maxima, cu suport tehnic dedicat.",
  },
  gazduirePremiumMeta: {
    title: "Gazduire Premium pentru Afaceri - Fiabilitate si Viteza",
    description:
      "Descopera pachetele noastre de gazduire premium, concepute pentru afaceri care necesita performanta si uptime exceptional. Suport premium inclus.",
  },
  gazduireWordpressMeta: {
    title: "Gazduire WordPress Optimizata - Start Rapid si Sigur",
    description:
      "Beneficiaza de gazduire WordPress optimizata pentru viteza si securitate. Instalare simpla, actualizari automate si suport tehnic expert pentru site-ul tau WordPress.",
  },
  gazduireEcommerceMeta: {
    title: "Gazduire E-commerce Sigura si Rapida pentru Magazinul Tau Online",
    description:
      "Crește-ți afacerea online cu gazduire e-commerce de încredere. Performanță optimă pentru platforme de comerț electronic, cu securitate îmbunătățită și suport dedicat.",
  },
  gazduireResellerMeta: {
    title: "Gazduire Reseller - Incepe Propria Ta Afacere de Hosting",
    description:
      "Lanseaza-ti afacerea de gazduire cu pachetele noastre reseller. Control complet, resurse scalabile si preturi competitive pentru a-ti creste afacerea de hosting.",
  },
  despreNoiMeta: {
    title: "Despre Noi - Echipa și Valorile Noastre",
    description:
      "Cunoaște echipa din spatele HostingSpace.ro, unde pasiunea pentru tehnologie și angajamentul pentru excelența în servicii de gazduire web ne definesc. Află mai multe despre misiunea și valorile noastre.",
  },
};

export default siteMeta;
