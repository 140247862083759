import React from "react";
import { Link, useLocation } from "react-router-dom";

const navItems = [
  {
    path: "/gazduire-web.html",
    label: "Gazduire Standard",
    
  },
  {
    path: "/gazduire-premium.html",
    label: "Premium",
    prefixMobile: "Gazduire ",
  },
  {
    path: "/gazduire-wordpress.html",
    label: "WordPress",
    prefixMobile: "Gazduire ",
  },
  {
    path: "/gazduire-ecommerce.html",
    label: "Ecommerce",
    prefixMobile: "Gazduire ",
  },
  { path: "/reseller.html", label: "Reseller", prefixMobile: "Gazduire ", },
  { path: "/despre-noi.html", label: "Despre Noi" },
];

const NavItems = ({ onLinkClick, isSticky }) => {
  // Accept isSticky prop
  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  const getLinkClassNames = (path) => {
    if (isActive(path)) {
      // Active state styling
      return `sub-menu-item ${isSticky ? "text-sky-500" : "text-sky-500 md2:text-white"}`;
    } else {
      // Not active state styling
      return `sub-menu-item ${
        isSticky
          ? "text-slate-900 hover:text-sky-500"
          : "text-slate-900 hover:text-sky-500 md2:text-white/50 md2:hover:text-white"
      }`;
    }
  };

  return (
    <ul className="flex flex-col md2:flex-row navigation-menu nav-light items-center md2:justify-end gap-y-4 md:gap-6 p-6 md:p-0 md2:gap-x-6">
      {navItems.map((item) => (
        <li
          key={item.label}
          className={`flex relative my-0 items-center ${
            isActive(item.path) ? "active" : ""
          }`}
        >
          <Link
            to={item.path}
            className={getLinkClassNames(item.path)}
            onClick={onLinkClick}
          >
            <span className="md2:hidden inline">{item.prefixMobile}</span>
            {item.label}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default NavItems;
