export const commonStandard = {
  title: "Caracteristici Comune ale Pachetului Standard",
  features: [
    "Trafic lunar Necontorizat",
    "Mediu de stocare SSD",
    "Cloudlinux Inclus",
    "Litespeed + Cache Inclus",
    "Emailuri/ ora 400",
    "IP Dedicat 60 lei / an",
    "SSL gratuit Let's Encrypt",
    "SpamExperts 50 lei / an",
    "Rapid SSL de la 60 lei / an",
    "Backup zilnic Da",
    "Transfer website Gratuit",
  ],
};
export const g10 = {
  title: "Gazduire 10GB",
  price: "120",
  link: "/gazduire-web.html",
  popular: false,
  features: [
    "Spatiu pe disc 10GB",
    "Procese simultan 20 procese",
    "RAM alocat 1.5 GB",
    "CPU alocat 3.6Ghz",
    "I/O - IOPS 3MB/s - 768",
    "Domenii gazduite 2",
    "Pret / 6 luni 80lei",
  ],
};
export const g25 = {
  title: "Gazduire 25GB",
  price: "250",
  link: "/gazduire-web.html",
  popular: true,
  features: [
    "Spatiu pe disc 25GB",
    "Procese simultan 20 procese",
    "RAM alocat 3GB",
    "CPU alocat 4.5Ghz",
    "I/O - IOPS 4.5MB/s - 1200",
    "Domenii gazduite 5",
    "Pret / 6 luni 150lei",
  ],
};
export const g50 = {
  title: "Gazduire 50GB",
  price: "500",
  link: "/gazduire-web.html",
  popular: false,
  features: [
    "Spatiu pe disc 50GB",
    "Procese simultan 20 procese",
    "RAM alocat 4.5GB",
    "CPU alocat 6.4Ghz",
    "I/O - IOPS 6MB/s - 1600",
    "Domenii gazduite 10",
    "Pret / 6 luni 250lei",
  ],
};
export const g100 = {
  title: "Gazduire 100GB",
  price: "1000",
  link: "/gazduire-web.html",
  popular: false,
  features: [
    "Spatiu pe disc 100GB",
    "Procese simultan 30 procese",
    "RAM alocat 6GB",
    "CPU alocat 9Ghz",
    "I/O - IOPS 7.5MB/s - 3072",
    "Domenii gazduite Necontorizat",
    "Pret / 6 luni 600lei",
  ],
};
export const g150 = {
  title: "Gazduire 150GB",
  price: "1500",
  link: "/gazduire-web.html",
  popular: false,
  features: [
    "Spatiu pe disc 150GB",
    "Procese simultan 30 procese",
    "RAM alocat 9GB",
    "CPU alocat 11Ghz",
    "I/O - IOPS 8MS/s - 4608",
    "Domenii gazduite Necontorizat",
  ],
};
export const g200 = {
  title: "Gazduire 200GB",
  price: "2000",
  link: "/gazduire-web.html",
  popular: false,
  features: [
    "Spatiu pe disc 200GB",
    "Procese simultan 30 procese",
    "RAM alocat 9GB",
    "CPU alocat 12Ghz",
    "I/O - IOPS 9MB/s - 6144",
    "Domenii gazduite Necontorizat",
  ],
};
export const commonPremium = {
  title: "Caracteristici Comune ale Pachetului Premium",
  features: [
    "Trafic lunar Necontorizat",
    "Mediu de stocare SSD",
    "Cloudlinux Inclus",
    "Litespeed + Cache Inclus",
    "Acces SSH Inclus",
    "Domeniu ro/com gratis DA",
    "Emailuri/ ora 2000 emailuri",
    "IP Dedicat 60 lei / an",
    "SSL gratuit Let's Encrypt",
    "SpamExperts 50 lei / an",
    "Rapid SSL de la 60 lei / an",
    "Backup zilnic Da",
    "Transfer website Gratuit",
  ],
};
export const p10 = {
  title: "Premium 10GB",
  price: "450",
  link: "/gazduire-premium.html",
  popular: false,
  features: [
    "Spatiu pe disc 10GB",
    "Procese simultan 40 procese",
    "RAM alocat 3GB",
    "CPU alocat 7Ghz",
    "I/O - IOPS 6MB/s - 1600",
    "Domenii gazduite 5",
  ],
};
export const p25 = {
  title: "Premium 25GB",
  price: "650",
  link: "/gazduire-premium.html",
  popular: true,
  features: [
    "Spatiu pe disc 25GB",
    "Procese simultan 40 procese",
    "RAM alocat 6GB",
    "CPU alocat 9Ghz",
    "I/O - IOPS 9MB/s - 2400",
    "Domenii gazduite 10",
  ],
};
export const p50 = {
  title: "Premium 50GB",
  price: "1300",
  link: "/gazduire-premium.html",
  popular: false,
  features: [
    "Spatiu pe disc 50GB",
    "Procese simultan 40 procese",
    "RAM alocat 9GB",
    "CPU alocat 12Ghz",
    "I/O - IOPS 12MB/s - 3200",
    "Domenii gazduite 20",
  ],
};
export const p100 = {
  title: "Premium 100GB",
  price: "2600",
  link: "/gazduire-premium.html",
  popular: false,
  features: [
    "Spatiu pe disc 100GB",
    "Procese simultan 40 procese",
    "RAM alocat 12GB",
    "CPU alocat 18Ghz",
    "I/O - IOPS 15MB/s - 6000",
    "Domenii gazduite Necontorizat",
  ],
};
export const p150 = {
  title: "Premium 150GB",
  price: "3900",
  link: "/gazduire-premium.html",
  popular: false,
  features: [
    "Spatiu pe disc 150GB",
    "Procese simultan 40 procese",
    "RAM alocat 18GB",
    "CPU alocat 20Ghz",
    "I/O - IOPS 8MS/s - 4608",
    "Domenii gazduite Necontorizat",
  ],
};
export const p200 = {
  title: "Premium 200GB",
  price: "5200",
  link: "/gazduire-premium.html",
  popular: false,
  features: [
    "Spatiu pe disc 200GB",
    "Procese simultan 40 procese",
    "RAM alocat 18GB",
    "CPU alocat 25Ghz",
    "I/O - IOPS 18MB/s - 12000",
    "Domenii gazduite Necontorizat",
  ],
};

export const commonWordpress = {
  title: "Caracteristici Comune ale Pachetului Wordpress",
  features: [
    "Trafic lunar Necontorizat",
    "Wordpress pre-instalat Instalare automata",
    "WP-Toolkit",
    "WP-CLI Da",
    "Mediu de stocare SSD",
    "Cloudlinux Inclus",
    "Litespeed + Cache Inclus",
    "Imunify360 Inclus",
    "Acces SSH Inclus",
    "Domeniu ro/com gratis DA",
    "Emailuri/ ora 2000 emailuri",
    "IP Dedicat 60 lei / an",
    "SSL gratuit Let's Encrypt",
    "SpamExperts 50 lei / an",
    "Rapid SSL de la 60 lei / an",
    "Backup zilnic Da",
    "Transfer website Gratuit",
  ],
};
export const wp10 = {
  title: "Wordpress 10GB",
  price: "450",
  link: "/gazduire-wordpress.html",
  popular: false,
  features: [
    "Spatiu pe disc 10GB",
    "Procese simultan 40 procese",
    "RAM alocat 3GB",
    "CPU alocat 7Ghz",
    "I/O - IOPS 6MB/s - 1600",
    "Domenii gazduite 1",
  ],
};
export const wp25 = {
  title: "Wordpress 25GB",
  price: "650",
  link: "/gazduire-wordpress.html",
  popular: true,
  features: [
    "Spatiu pe disc 25GB",
    "Procese simultan 40 procese",
    "RAM alocat 6GB",
    "CPU alocat 9Ghz",
    "I/O - IOPS 9MB/s - 2400",
    "Domenii gazduite 3",
  ],
};
export const wp50 = {
  title: "Wordpress 50GB",
  price: "1300",
  link: "/gazduire-wordpress.html",
  popular: false,
  features: [
    "Spatiu pe disc 50GB",
    "Procese simultan 40 procese",
    "RAM alocat 9GB",
    "CPU alocat 12Ghz",
    "I/O - IOPS 12MB/s - 3200",
    "Domenii gazduite 10",
  ],
};
export const wp100 = {
  title: "Wordpress 100GB",
  price: "2600",
  link: "/gazduire-wordpress.html",
  popular: false,
  features: [
    "Spatiu pe disc 100GB",
    "Procese simultan 40 procese",
    "RAM alocat 12GB",
    "CPU alocat 18Ghz",
    "I/O - IOPS 15MB/s - 6000",
    "Domenii gazduite Necontorizat",
  ],
};
export const wp150 = {
  title: "Wordpress 150GB",
  price: "3900",
  link: "/gazduire-wordpress.html",
  popular: false,
  features: [
    "Spatiu pe disc 150GB",
    "Procese simultan 40 procese",
    "RAM alocat 18GB",
    "CPU alocat 20Ghz",
    "I/O - IOPS 8MS/s - 4608",
    "Domenii gazduite Necontorizat",
  ],
};
export const wp200 = {
  title: "Wordpress 200GB",
  price: "5200",
  link: "/gazduire-wordpress.html",
  popular: false,
  features: [
    "Spatiu pe disc 200GB",
    "Procese simultan 40 procese",
    "RAM alocat 18GB",
    "CPU alocat 25Ghz",
    "I/O - IOPS 18MB/s - 12000",
    "Domenii gazduite Necontorizat",
  ],
};
export const commonEcommerce = {
  title: "Caracteristici Comune ale Pachetului e-Commerce",
  features: [
    "Trafic lunar Necontorizat",
    "Mediu de stocare SSD",
    "Cloudlinux Inclus",
    "Litespeed + Cache Inclus",
    "Acces SSH Inclus",
    "Domeniu ro/com gratis DA",
    "IP Dedicat 60 lei / an",
    "SSL gratuit Let's Encrypt",
    "SpamExperts 50 lei / an",
    "Rapid SSL de la 60 lei / an",
    "Backup zilnic Da",
    "Transfer website Gratuit",
  ],
};
export const e10 = {
  title: "e-Commerce 10GB",
  price: "430",
  link: "/gazduire-ecommerce.html",
  popular: false,
  features: [
    "Spatiu pe disc 10GB",
    "Procese simultan 40 procese",
    "RAM alocat 1GB",
    "Domenii gazduite Maxim 10",
    "Emailuri/ ora 400 emailuri",
  ],
};
export const e25 = {
  title: "e-Commerce 25GB",
  price: "250",
  link: "/gazduire-ecommerce.html",
  popular: true,
  features: [
    "Spatiu pe disc 25GB",
    "Procese simultan 40 procese",
    "RAM alocat 2GB",
    "Domenii gazduite Necontorizat",
    "Emailuri/ ora 2000 emailuri",
  ],
};
export const e50 = {
  title: "e-Commerce 50GB",
  price: "500",
  link: "/gazduire-ecommerce.html",
  popular: false,
  features: [
    "Spatiu pe disc 50GB",
    "Procese simultan 40 procese",
    "RAM alocat 2GB",
    "Domenii gazduite Necontorizat",
    "Emailuri/ ora 2000 emailuri",
  ],
};
export const e100 = {
  title: "e-Commerce 100GB",
  price: "750",
  link: "/gazduire-ecommerce.html",
  popular: false,
  features: [
    "Spatiu pe disc 100GB",
    "Procese simultan 40 procese",
    "RAM alocat 3GB",
    "Domenii gazduite Necontorizat",
    "Emailuri/ ora 2000 emailuri",
  ],
};
export const e150 = {
  title: "e-Commerce 150GB",
  price: "1000",
  link: "/gazduire-ecommerce.html",
  popular: false,
  features: [
    "Spatiu pe disc 150GB",
    "Procese simultan 40 procese",
    "RAM alocat 3GB",
    "Domenii gazduite Necontorizat",
    "Emailuri/ ora 2000 emailuri",
  ],
};
export const e200 = {
  title: "e-Commerce 200GB",
  price: "1500",
  link: "/gazduire-ecommerce.html",
  popular: false,
  features: [
    "Spatiu pe disc 200GB",
    "Procese simultan 40 procese",
    "RAM alocat 3GB",
    "Domenii gazduite Necontorizat",
    "Emailuri/ ora 2000 emailuri",
  ],
};
export const rs10 = {
  title: "Reseller 10GB",
  price: "430",
  link: "/reseller.html",
  popular: false,
  features: [
    "Spatiu pe disc 10GB",
    "Domeniu ro/com gratis DA",
    "Trafic lunar Necontorizat",
    "Mediu de stocare SSD",
    "Procese simultan 40 procese",
    "RAM alocat 1GB",
    "Acces SSH Inclus",
    "Domenii gazduite Maxim 10",
    "Emailuri/ ora 400",
    "Ip Dedicat 60 lei / an",
    "SSL gratuit Let's encrypt",
    "Rapid SSL de la 60 lei / an",
    "Backup zilnic Da",
    "Transfer website* Gratuit",
  ],
};
export const rs25 = {
  title: "Reseller 25GB",
  price: "250",
  link: "/reseller.html",
  popular: true,
  features: [
    "Spatiu pe disc 25GB",
    "Domeniu ro/com gratis DA",
    "Trafic lunar Necontorizat",
    "Mediu de stocare SSD",
    "Procese simultan 40 procese",
    "RAM alocat 2GB",
    "Acces SSH Inclus",
    "Domenii gazduite Necontorizat",
    "Emailuri/ ora 400",
    "Ip Dedicat 60 lei / an",
    "SSL gratuit Let's encrypt",
    "Rapid SSL de la 60 lei / an",
    "Backup zilnic Da",
    "Transfer website* Gratuit",
  ],
};
export const rs50 = {
  title: "Reseller 50GB",
  price: "500",
  link: "/reseller.html",
  popular: false,
  features: [
    "Spatiu pe disc 50GB",
    "Domeniu ro/com gratis DA",
    "Trafic lunar Necontorizat",
    "Mediu de stocare SSD",
    "Procese simultan 40 procese",
    "RAM alocat 2GB",
    "Acces SSH Inclus",
    "Domenii gazduite Necontorizat",
    "Emailuri/ ora 400",
    "Ip Dedicat 60 lei / an",
    "SSL gratuit Let's encrypt",
    "Rapid SSL de la 60 lei / an",
    "Backup zilnic Da",
    "Transfer website* Gratuit",
  ],
};
export const rs100 = {
  title: "Reseller 100GB",
  price: "750",
  link: "/reseller.html",
  popular: false,
  features: [
    "Spatiu pe disc 100GB",
    "Domeniu ro/com gratis DA",
    "Trafic lunar Necontorizat",
    "Mediu de stocare SSD",
    "Procese simultan 40 procese",
    "RAM alocat 2GB",
    "Acces SSH Inclus",
    "Domenii gazduite Necontorizat",
    "Emailuri/ ora 400",
    "Ip Dedicat 60 lei / an",
    "SSL gratuit Let's encrypt",
    "Rapid SSL de la 60 lei / an",
    "Backup zilnic Da",
    "Transfer website* Gratuit",
  ],
};
export const rs150 = {
  title: "Reseller 150GB",
  price: "1000",
  link: "/reseller.html",
  popular: false,
  features: [
    "Spatiu pe disc 150GB",
    "Domeniu ro/com gratis DA",
    "Trafic lunar Necontorizat",
    "Mediu de stocare SSD",
    "Procese simultan 40 procese",
    "RAM alocat 3GB",
    "Acces SSH Inclus",
    "Domenii gazduite Necontorizat",
    "Emailuri/ ora 400",
    "Ip Dedicat 60 lei / an",
    "SSL gratuit Let's encrypt",
    "Rapid SSL de la 60 lei / an",
    "Backup zilnic Da",
    "Transfer website* Gratuit",
  ],
};
export const rs200 = {
  title: "Reseller 200GB",
  price: "1500",
  link: "/reseller.html",
  popular: false,
  features: [
    "Spatiu pe disc 200GB",
    "Domeniu ro/com gratis DA",
    "Trafic lunar Necontorizat",
    "Mediu de stocare SSD",
    "Procese simultan 40 procese",
    "RAM alocat 3GB",
    "Acces SSH Inclus",
    "Domenii gazduite Necontorizat",
    "Emailuri/ ora 400",
    "Ip Dedicat 60 lei / an",
    "SSL gratuit Let's encrypt",
    "Rapid SSL de la 60 lei / an",
    "Backup zilnic Da",
    "Transfer website* Gratuit",
  ],
};
