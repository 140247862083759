import React from "react";
import mainImage from "../assets/images/3.png"; // Adjust the path as per your project structure

const FeatureSection = () => {
  return (
    <div className="container relative md:mt-24 mt-16">
      <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-10 gap-[30px]">
        <div className="lg:col-span-6">
          <div className="lg:me-8">
            <img src={mainImage} alt="Main Feature" />
          </div>
        </div>

        <div className="lg:col-span-6">
          <div className="grid grid-cols-1 gap-[30px]">
            {/* Feature 1 */}
            <div className="group flex items-center relative overflow-hidden p-6 rounded-md shadow dark:shadow-gray-800 hover:bg-sky-500 dark:hover:bg-sky-500 transition-all duration-500 ease-in-out">
              <span className="text-sky-500 group-hover:text-white text-5xl font-semibold transition-all duration-500 ease-in-out">
                <i className="uil uil-swatchbook"></i>
              </span>
              <div className="flex-1 ms-3">
                <h5 className="group-hover:text-white text-xl font-semibold transition-all duration-500 ease-in-out">
                  Siguranta avansata
                </h5>
                <p className="text-slate-400 group-hover:text-white/50 transition-all duration-500 ease-in-out mt-2">
                  Protejati-va site-ul web si datele cu o gama de masuri de
                  securitate robuste, inclusiv firewall-uri avansate,
                  monitorizare 24/7 si protectie impotriva atacurilor
                  cibernetice.
                </p>
              </div>
              <i className="absolute start-1 top-5 text-dark/[0.03] dark:text-white/[0.03] text-8xl group-hover:text-white/[0.04] transition-all duration-500 ease-in-out uil uil-swatchbook"></i>
            </div>

            {/* Feature 2 */}
            <div className="group flex items-center relative overflow-hidden p-6 rounded-md shadow dark:shadow-gray-800 hover:bg-sky-500 dark:hover:bg-sky-500 transition-all duration-500 ease-in-out">
              <span className="text-sky-500 group-hover:text-white text-5xl font-semibold transition-all duration-500 ease-in-out">
                <i className="uil uil-tachometer-fast-alt"></i>
              </span>
              <div className="flex-1 ms-3">
                <h5 className="group-hover:text-white text-xl font-semibold transition-all duration-500 ease-in-out">
                  Servere performante
                </h5>
                <p className="text-slate-400 group-hover:text-white/50 transition-all duration-500 ease-in-out mt-2">
                  Bucurati-va de performanta optimizata si viteza de incarcare
                  rapida cu serverele noastre de ultima generatie, concepute
                  pentru a sustine chiar si cele mai solicitante site-uri web.
                </p>
              </div>
              <i className="absolute start-1 top-5 text-dark/[0.03] dark:text-white/[0.03] text-8xl group-hover:text-white/[0.04] transition-all duration-500 ease-in-out uil uil-tachometer-fast-alt"></i>
            </div>

            {/* Feature 3 */}
            <div className="group flex items-center relative overflow-hidden p-6 rounded-md shadow dark:shadow-gray-800 hover:bg-sky-500 dark:hover:bg-sky-500 transition-all duration-500 ease-in-out">
              <span className="text-sky-500 group-hover:text-white text-5xl font-semibold transition-all duration-500 ease-in-out">
                <i className="uil uil-user-check"></i>
              </span>
              <div className="flex-1 ms-3">
                <h5 className="group-hover:text-white text-xl font-semibold transition-all duration-500 ease-in-out">
                  Asistenta tehnica 24/7
                </h5>
                <p className="text-slate-400 group-hover:text-white/50 transition-all duration-500 ease-in-out mt-2">
                  Aveti acces la asistenta dedicata si prompta pentru a va ajuta
                  cu orice intrebare sau problema pe care o puteti avea cu
                  hosting-ul dvs. web.
                </p>
              </div>
              <i className="absolute start-1 top-5 text-dark/[0.03] dark:text-white/[0.03] text-8xl group-hover:text-white/[0.04] transition-all duration-500 ease-in-out uil uil-user-check"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureSection;
