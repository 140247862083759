import React from 'react';
import { Link } from 'react-router-dom';
import { Hexagon } from 'react-feather';


const DedicatedServers = () => {
  return (
    <div className="container">
                <div className="grid lg:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                    <div className="lg:col-span-5 lg:pb-0 pb-8 lg:text-start text-center">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-medium">Why Choose Dedicated Server?</h3>
                        <p className="text-slate-400 max-w-xl lg:text-start mx-auto">Create, collaborate, and turn your ideas into incredible products with the definitive platform for digital design.</p>
                        
                        {/* <div className="mt-4">
                            <a href="page-aboutus.html" className="relative inline-block tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:transition-all after:duration-500 text-sky-500 hover:text-sky-500 after:bg-sky-500 duration-500 ease-in-out">Find Out More <i className="uil uil-angle-right-b align-middle"></i></a>
                        </div> */}
                    </div>

                    <div className="lg:col-span-7 lg:mt-0 mt-8">
                        <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
                            <div className="group relative p-6 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-sky-500 dark:hover:bg-sky-500 transition-all duration-500 ease-in-out rounded-md bg-white dark:bg-slate-900 overflow-hidden h-fit">
                                <div className="relative overflow-hidden text-transparent -m-3">
                                    <Hexagon className="h-24 w-24 fill-sky-500/[0.07] group-hover:fill-white/20"/>
                                    <div className="absolute top-2/4 -translate-y-2/4 start-8 text-sky-500 rounded-md group-hover:text-white transition-all duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
                                        <i className="uil uil-server"></i>
                                    </div>
                                </div>
        
                                <div className="mt-6">
                                    <h5><a href="" className="text-lg font-medium group-hover:text-white transition-all duration-500 ease-in-out">Web Hosting</a></h5>
                                    <p className="text-slate-400 group-hover:text-white/50 transition-all duration-500 ease-in-out mt-3">The phrasal sequence of the is now so that many campaign and benefit</p>
                                </div>
                            </div>
        
                            <div className="grid grid-cols-1 items-center gap-[30px]">
                                <div className="group relative p-6 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-sky-500 dark:hover:bg-sky-500 transition-all duration-500 ease-in-out rounded-md bg-white dark:bg-slate-900 overflow-hidden h-fit">
                                    <div className="relative overflow-hidden text-transparent -m-3">
                                        <Hexagon className="h-24 w-24 fill-sky-500/[0.07] group-hover:fill-white/20"/>
                                        <div className="absolute top-2/4 -translate-y-2/4 start-8 text-sky-500 rounded-md group-hover:text-white transition-all duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
                                            <i className="uil uil-cloud-heart"></i>
                                        </div>
                                    </div>
            
                                    <div className="mt-6">
                                        <h5><a href="" className="text-lg font-medium group-hover:text-white transition-all duration-500 ease-in-out">Domains</a></h5>
                                        <p className="text-slate-400 group-hover:text-white/50 transition-all duration-500 ease-in-out mt-3">The phrasal sequence of the is now so that many campaign and benefit</p>
                                    </div>
                                </div>
            
                                <div className="group relative p-6 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-sky-500 dark:hover:bg-sky-500 transition-all duration-500 ease-in-out rounded-md bg-white dark:bg-slate-900 overflow-hidden h-fit">
                                    <div className="relative overflow-hidden text-transparent -m-3">
                                        <Hexagon className="h-24 w-24 fill-sky-500/[0.07] group-hover:fill-white/20"/>
                                        <div className="absolute top-2/4 -translate-y-2/4 start-8 text-sky-500 rounded-md group-hover:text-white transition-all duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
                                            <i className="uil uil-envelope-check"></i>
                                        </div>
                                    </div>
            
                                    <div className="mt-6">
                                        <h5><a href="" className="text-lg font-medium group-hover:text-white transition-all duration-500 ease-in-out">Emails</a></h5>
                                        <p className="text-slate-400 group-hover:text-white/50 transition-all duration-500 ease-in-out mt-3">The phrasal sequence of the is now so that many campaign and benefit</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
  );
};

export default DedicatedServers;
