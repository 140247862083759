import React from "react";
import logoDark from "../../assets/images/logo-dark.png";
import logoWhite from "../../assets/images/logo-white.png";

// Accept isSticky as a prop
const Logo = ({ isSticky }) => {
  // Determine which logo to show based on isSticky
  const logo = isSticky ? logoDark : logoWhite;

  return (
    <a className="logo content-center" href="/">
      <img src={logo} className="h-12 inline-block" alt="Logo" />
    </a>
  );
};

export default Logo;
