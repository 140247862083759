import React from "react";
import NavItems from "./navItems";

const NavigationDropdown = ({ isOpen }) => {
  return (
    <div
      className={`absolute top-full left-0  w-full bg-white dark:bg-slate-900 shadow-md overflow-hidden transition-all ease-out duration-300 ${isOpen ? 'max-h-screen py-8' : 'h-0'}`}
    >
      <NavItems />
    </div>
  );
};

export default NavigationDropdown;
