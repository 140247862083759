import React from "react";
import { Hexagon } from "react-feather";

const SixCardFeatures = () => {
  return (
    <div className="container lg mx-auto mt-24">
      <div className="grid grid-cols-1 pb-8 text-center">
        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-medium">
          Features
        </h3>

        <p className="text-slate-400 max-w-xl mx-auto">
          Create, collaborate, and turn your ideas into incredible products with
          the definitive platform for digital design.
        </p>
      </div>
      <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-4 gap-[30px]">
        <div className="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center">
          <div className="relative overflow-hidden text-transparent -m-3">
            <Hexagon
              className="h-28 w-28 fill-sky-500/5 mx-auto rotate-[30deg]"
            />
            <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-sky-500 rounded-xl transition duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
              <i className="uil uil-server"></i>
            </div>
          </div>

          <div className="mt-6">
            <h5>
              <a
                href=""
                className="text-lg font-medium hover:text-sky-500 transition-all duration-500 ease-in-out"
              >
                Web Hosting
              </a>
            </h5>
            <p className="text-slate-400 transition-all duration-500 ease-in-out mt-3">
              The phrasal sequence of the is now so that many campaign and
              benefit
            </p>

            <div className="mt-4">
              <a
                href=""
                className="relative inline-block tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:transition-all after:duration-500 text-sky-500 hover:text-sky-500 after:bg-sky-500 duration-500 ease-in-out"
              >
                Read More <i className="uil uil-angle-right-b align-middle"></i>
              </a>
            </div>
          </div>
        </div>

        <div className="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center">
          <div className="relative overflow-hidden text-transparent -m-3">
            <Hexagon
              className="h-28 w-28 fill-sky-500/5 mx-auto rotate-[30deg]"
            />
            <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-sky-500 rounded-xl transition duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
              <i className="uil uil-cloud-heart"></i>
            </div>
          </div>

          <div className="mt-6">
            <h5>
              <a
                href=""
                className="text-lg font-medium hover:text-sky-500 transition-all duration-500 ease-in-out"
              >
                Domains
              </a>
            </h5>
            <p className="text-slate-400 transition-all duration-500 ease-in-out mt-3">
              The phrasal sequence of the is now so that many campaign and
              benefit
            </p>

            <div className="mt-4">
              <a
                href=""
                className="relative inline-block tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:transition-all after:duration-500 text-sky-500 hover:text-sky-500 after:bg-sky-500 duration-500 ease-in-out"
              >
                Read More <i className="uil uil-angle-right-b align-middle"></i>
              </a>
            </div>
          </div>
        </div>

        <div className="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center">
          <div className="relative overflow-hidden text-transparent -m-3">
            <Hexagon
              className="h-28 w-28 fill-sky-500/5 mx-auto rotate-[30deg]"
            />
            <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-sky-500 rounded-xl transition duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
              <i className="uil uil-envelope-check"></i>
            </div>
          </div>

          <div className="mt-6">
            <h5>
              <a
                href=""
                className="text-lg font-medium hover:text-sky-500 transition-all duration-500 ease-in-out"
              >
                Emails
              </a>
            </h5>
            <p className="text-slate-400 transition-all duration-500 ease-in-out mt-3">
              The phrasal sequence of the is now so that many campaign and
              benefit
            </p>

            <div className="mt-4">
              <a
                href=""
                className="relative inline-block tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:transition-all after:duration-500 text-sky-500 hover:text-sky-500 after:bg-sky-500 duration-500 ease-in-out"
              >
                Read More <i className="uil uil-angle-right-b align-middle"></i>
              </a>
            </div>
          </div>
        </div>

        <div className="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center">
          <div className="relative overflow-hidden text-transparent -m-3">
            <Hexagon
              className="h-28 w-28 fill-sky-500/5 mx-auto rotate-[30deg]"
            />
            <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-sky-500 rounded-xl transition duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
              <i className="uil uil-users-alt"></i>
            </div>
          </div>

          <div className="mt-6">
            <h5>
              <a
                href=""
                className="text-lg font-medium hover:text-sky-500 transition-all duration-500 ease-in-out"
              >
                Supported
              </a>
            </h5>
            <p className="text-slate-400 transition-all duration-500 ease-in-out mt-3">
              The phrasal sequence of the is now so that many campaign and
              benefit
            </p>

            <div className="mt-4">
              <a
                href=""
                className="relative inline-block tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:transition-all after:duration-500 text-sky-500 hover:text-sky-500 after:bg-sky-500 duration-500 ease-in-out"
              >
                Read More <i className="uil uil-angle-right-b align-middle"></i>
              </a>
            </div>
          </div>
        </div>

        <div className="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center">
          <div className="relative overflow-hidden text-transparent -m-3">
            <Hexagon
              className="h-28 w-28 fill-sky-500/5 mx-auto rotate-[30deg]"
            />
            <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-sky-500 rounded-xl transition duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
              <i className="uil uil-tachometer-fast-alt"></i>
            </div>
          </div>

          <div className="mt-6">
            <h5>
              <a
                href=""
                className="text-lg font-medium hover:text-sky-500 transition-all duration-500 ease-in-out"
              >
                Speedy
              </a>
            </h5>
            <p className="text-slate-400 transition-all duration-500 ease-in-out mt-3">
              The phrasal sequence of the is now so that many campaign and
              benefit
            </p>

            <div className="mt-4">
              <a
                href=""
                className="relative inline-block tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:transition-all after:duration-500 text-sky-500 hover:text-sky-500 after:bg-sky-500 duration-500 ease-in-out"
              >
                Read More <i className="uil uil-angle-right-b align-middle"></i>
              </a>
            </div>
          </div>
        </div>

        <div className="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center">
          <div className="relative overflow-hidden text-transparent -m-3">
            <Hexagon
              className="h-28 w-28 fill-sky-500/5 mx-auto rotate-[30deg]"
            />
            <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-sky-500 rounded-xl transition duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
              <i className="uil uil-compact-disc"></i>
            </div>
          </div>

          <div className="mt-6">
            <h5>
              <a
                href=""
                className="text-lg font-medium hover:text-sky-500 transition-all duration-500 ease-in-out"
              >
                Reliable
              </a>
            </h5>
            <p className="text-slate-400 transition-all duration-500 ease-in-out mt-3">
              The phrasal sequence of the is now so that many campaign and
              benefit
            </p>

            <div className="mt-4">
              <a
                href=""
                className="relative inline-block tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:transition-all after:duration-500 text-sky-500 hover:text-sky-500 after:bg-sky-500 duration-500 ease-in-out"
              >
                Read More <i className="uil uil-angle-right-b align-middle"></i>
              </a>
            </div>
          </div>
        </div>
      </div>{" "}
    </div>
  );
};

export default SixCardFeatures;
