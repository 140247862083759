import React from "react";
import HostingHero from "../components/hostingPages/HostingHero";
import DedicatedServers from "../components/hostingPages/DedicatedServers";
import PricingTable from "../components/pricing/PricingTable";
import SixCardFeatures from "../components/hostingPages/SixCardFeatures";
import FAQs from "../components/FAQs/FAQs";
import { gazduireEcommerceData } from "../components/pricing/DataStructure";
import { Helmet } from "react-helmet";
import siteMeta from "../components/meta/siteMeta";
import { fullTitle } from "../components/meta/siteTitle";
import CommonTable from "../components/pricing/CommonTable";
import { commonEcommerce } from "../components/pricing/Pachete";

const GazduireEcommerce = () => {
  return (
    <>
      <Helmet>
        <title>{fullTitle(siteMeta.gazduireEcommerceMeta.title)}</title>
        <meta
          name="description"
          content={siteMeta.gazduireEcommerceMeta.description}
        />
      </Helmet>
      <HostingHero pageName="GazduireEcommerce" />
      <section className="relative md:py-24 py-16">
        <PricingTable pricingData={gazduireEcommerceData} />
        <CommonTable commonData={commonEcommerce} />
        <div className="md:mt-24 mt-16">
          <DedicatedServers />
        </div>
        <SixCardFeatures />
        <FAQs pageId="ecommerce" />
      </section>
    </>
  );
};

export default GazduireEcommerce;
