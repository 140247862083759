import React from "react";
import HeroSection from "../components/HeroSection";
import HostingServices from "../components/HostingServices";
import QuickResponse from "../components/QuickResponse";
import PricingTable from "../components/pricing/PricingTable";
import FeatureSection from "../components/FeatureSection";
import TrustedUsers from "../components/TrustedUser";
import MySlider from "../components/MySlider";
import { gazduireHomepageData } from "../components/pricing/DataStructure";

const Homepage = () => {
  return (
    <>
      <section className="relative lg:py-44 py-36 bg-gradient-to-br from-sky-700 to-blue-600">
        <HeroSection />
      </section>
      <section className="relative md:pb-24 pb-16">
        <div className="md:mt-24 mt-16">
            <PricingTable pricingData={gazduireHomepageData} />
          </div>
        <QuickResponse />
        <HostingServices />
        <FeatureSection />
        <TrustedUsers />
        <MySlider />
      </section>
    </>
  );
};

export default Homepage;
