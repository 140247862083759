import React from "react";
import HostingHero from "../components/hostingPages/HostingHero";
import DedicatedServers from "../components/hostingPages/DedicatedServers";
import PricingTable from "../components/pricing/PricingTable";
import SixCardFeatures from "../components/hostingPages/SixCardFeatures";
import Faqs from "../components/Faqs";
import { gazduireWordpressData } from "../components/pricing/DataStructure";
import { Helmet } from "react-helmet";
import siteMeta from "../components/meta/siteMeta";
import { fullTitle } from "../components/meta/siteTitle";
import CommonTable from "../components/pricing/CommonTable";
import { commonWordpress } from "../components/pricing/Pachete";

const GazduireWordpress = () => {
  return (
    <>
      <Helmet>
        <title>{fullTitle(siteMeta.gazduireWordpressMeta.title)}</title>
        <meta name="description" content={siteMeta.gazduireWordpressMeta.description} />
      </Helmet>
      <HostingHero pageName="GazduireWordpress" />
      <section className="relative md:py-24 py-16">
        
        <PricingTable pricingData={gazduireWordpressData} />
        <CommonTable commonData={commonWordpress}/>
        <div className="md:mt-24 mt-16">
          <DedicatedServers />
        </div>
        <SixCardFeatures />
        <Faqs />
      </section>
    </>
  );
};

export default GazduireWordpress;
