import React, { useState } from "react";
import despreimg from "../assets/images/despre.jpg";
import datacenterimg from "../assets/images/datacenter.jpg";
import { Link } from "react-router-dom";

const TabWidget = () => {
  const [activeTab, setActiveTab] = useState("desprenoi");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="grid md:grid-cols-12 grid-cols-1 mt-10 gap-[30px]">
      <div className="lg:col-span-3 md:col-span-5">
        <div className="sticky top-20">
          <ul className="flex-column p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
            <li role="presentation">
              <button
                className={`px-4 py-2 text-start text-base font-semibold rounded-md w-full transition-all duration-500 ease-in-out ${
                  activeTab === "desprenoi" ? "bg-sky-500 text-white" : ""
                }`}
                onClick={() => handleTabClick("desprenoi")}
              >
                Despre noi
              </button>
            </li>
            <li role="presentation">
              <button
                className={`px-4 py-2 text-start text-base font-semibold rounded-md w-full mt-3 transition-all duration-500 ease-in-out ${
                  activeTab === "datacentre" ? "bg-sky-500 text-white" : ""
                }`}
                onClick={() => handleTabClick("datacentre")}
              >
                Centru de Date
              </button>
            </li>
            <li role="presentation">
              <button
                className={`px-4 py-2 text-start text-base font-semibold rounded-md w-full mt-3 transition-all duration-500 ease-in-out ${
                  activeTab === "parteneriate" ? "bg-sky-500 text-white" : ""
                }`}
                onClick={() => handleTabClick("parteneriate")}
              >
                Parteneriate
              </button>
            </li>
            <li role="presentation">
              <button
                className={`px-4 py-2 text-start text-base font-semibold rounded-md w-full mt-3 transition-all duration-500 ease-in-out ${
                  activeTab === "backup" ? "bg-sky-500 text-white" : ""
                }`}
                onClick={() => handleTabClick("backup")}
              >
                Back-up JetBackup
              </button>
            </li>
            <li role="presentation">
              <button
                className={`px-4 py-2 text-start text-base font-semibold rounded-md w-full mt-3 transition-all duration-500 ease-in-out ${
                  activeTab === "domenii" ? "bg-sky-500 text-white" : ""
                }`}
                onClick={() => handleTabClick("domenii")}
              >
                Parteneriate domenii.ro
              </button>
            </li>
          </ul>
        </div>
      </div>

      <div className="lg:col-span-9 md:col-span-7">
        <div className="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
          <div
            className={activeTab === "desprenoi" ? "" : "hidden"}
            role="tabpanel"
          >
            {/* Image on top */}
            <img
              src={despreimg} // replace with your actual image path
              alt="Despre Noi"
              className="w-full object-cover" // adjust these classes as needed for your layout
            />

            {/* Text content below the image */}
            <div className="text-content mt-4 px-4">
              {" "}
              {/* Add margin-top and padding as needed */}
              <h3 className="text-xl font-semibold text-slate-700 mt-4 mb-2">
                Misiunea HostingSpace
              </h3>
              <p className="text-slate-400">
                HostingSpace.ro este un serviciu profesional de hosting lansat
                in Septembrie 2008. Cu un brand nou si plasand clientul in
                centrul gandirii nostre SC Jordan Tech SRL isi prezinta
                serviciile de gazduire si domenii web. Misiunea HostingSpace
                este sa ofere hosting web tinand cont de nevoile clientului in
                materie de spatiu, viteza si uptime.
              </p>
              <h3 className="text-xl font-semibold text-slate-700 mt-4 mb-2">
                Serverele HostingSpace
              </h3>
              <p className="text-slate-400">
                Folosim servere performante pentru ca stim cat de imporant este
                pentru dumneavoastra siteul web. Este imporant ca siteul
                dumneavoastra sa aibe la dispozitie mereu echipamente
                performante care pot sa il puna in valoare. Ne orientam catre
                fiabilitate si putere de calcul atunci cand lansam un server web
                si pastram mereu un standard ridicat prin upgrade-uri periodice.
              </p>
              <h3 className="text-xl font-semibold text-slate-700 mt-4 mb-2">
                Suportul tehnic
              </h3>
              <p className="text-slate-400">
                Aveti nevoie de o companie care poate sa va inteleaga nevoile
                legate de suport tehnic si sa va ajute sa le rezolvati cat mai
                repede problemele. Suportul tehnic este o prioritate pentru noi.
                Oferim prin email sau direct pe site printr-o aplicatie
                profesionala de suport. 24 ore pe zi, 7 zile pe saptamana.
                Pentru intrebari legate de vanzari va stam la dispozitie si la
                telefon. Afla cum ne poti contacta eficient aici, in functie de
                informatia de care ai nevoie.
              </p>
            </div>
          </div>

          <div
            className={activeTab === "datacentre" ? "" : "hidden"}
            role="tabpanel"
            aria-labelledby="datacentre-tab"
          >
            {/* Additional Information content */}
            <img
              src={datacenterimg} // replace with your actual image path
              alt="Descriptive Alt Text"
              className="w-full object-cover" // adjust these classes as needed for your layout
            />

            {/* Text content below the image */}
            <div className="text-content mt-4 px-4">
              {/* Add margin-top and padding as needed */}
              <h3 className="text-xl font-semibold text-slate-700 mb-2">
                Centru de date
              </h3>
              <p className="text-slate-400">
                Ne gazduim serverele in data centerul EFX din Iasi, in conditii
                optime. Pentru ca temperatura la care opereaza serverele este
                foarte importanta in acest domeniu ne asiguram ca intotdeauna
                climatizarea mentine un nivel de 22 grade Celsius. Conectarea la
                internet in data center se face prin 3 provideri (GTS Telecom,
                Combridge, AdNet Telecom). Fiecare provider are propriul traseu
                de fibra optica (ceea ce ne ajuta sa facem fata la situatii
                neprevazute). Pentru securitate, datacenterul este pazit de
                catre o firma privata si beneficiaza de supraveghere video. In
                cadrul datacenterului exista generator de electricitate, UPS-uri
                pentru fiecare rack in part iar reteaua electrica beneficiaza de
                puncte de alimentare multiple.
              </p>
            </div>
          </div>

          <div
            className={activeTab === "parteneriate" ? "" : "hidden"}
            role="tabpanel"
            aria-labelledby="parteneriate-tab"
          >
            {/* Additional Information content */}
            <img
              src={datacenterimg} // replace with your actual image path
              alt="Descriptive Alt Text"
              className="w-full object-cover" // adjust these classes as needed for your layout
            />

            {/* Text content below the image */}
            <div className="text-content mt-4 px-4">
              {" "}
              {/* Add margin-top and padding as needed */}
              <h3 className="text-xl font-semibold text-slate-700 mt-4 mb-2">
                Parteneriate HostingSpace
              </h3>
              <p className="text-slate-400">
                HostingSpace.ro cauta activ parteneri noi pentru a imbunatati
                serviciile pe care le ofera clientilor. In acelasi timp avem
                grija ca parteneriatele pe care le oferim sa fie mereu in
                sprijinul si interesul clientilor. Cautam permanent metode prin
                care sa va oferim o mai buna administrare a contului de gazduire
                sau un pret competitiv atunci cand discutam despre serviciile pe
                care le oferim.
              </p>
              <h3 className="text-xl font-semibold text-slate-700 mt-4 mb-2">
                Parteneriat TLD-uri (domenii)
              </h3>
              <p className="text-slate-400">
                HostingSpace este Registrar
                <a
                  href="https://rotld.ro/registrar-list/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-sky-500"
                >
                  {" "}
                  (partener autorizat ROTLD)
                </a>
                pentru domeniile .ro si in acelasi timp avem parteneriate cu
                unul dintre cei mai mari Registrari pentru alte tipuri de
                domenii (.com, .net, .org). Prin aceste parteneriate ne asiguram
                ca dvs beneficiati atat de cele mai bune preturi la
                inregistrarea domeniilor cat si de un serviciu de calitate.
                Lista completa a extensiilor de domenii pe care le oferim le
                gasiti
                <a
                  href="https://www.hostingspace.ro/client/domainchecker.php"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-sky-500"
                >
                  {" "}
                  aici
                </a>
                .
              </p>
              <h3 className="text-xl font-semibold text-slate-700 mt-4 mb-2">
                Parteneriat cPanel si WHM
              </h3>
              <p className="text-slate-400">
                Pentru toate serviciile oferite avem posibilitatea de a va oferi
                cel mai complet si usor de folosit panou de control din
                industrie. Acest panou de control (cPanel) este inclus gratuit
                in toate serviciile noastre de gazduire web si reseller si poate
                fi achizitonat separat pentru serviciile de VPS si Servere
                dedicate
              </p>
              <h3 className="text-xl font-semibold text-slate-700 mt-4 mb-2">
                Parteneriat WHMCS
              </h3>
              <p className="text-slate-400">
                Daca aveti o afacere in hosting si aveti la noi un cont de
                reseller, VPS sau server dedicat / colocat va putem pune la
                dispozitie o licenta pentru programul WHMCS. Acesta este un soft
                creat special pentru piata de hosting prin care gestionarea
                clientilor, domeniilor, pachetelor de gazduire si a ticketelor
                de suport devine o munca usor de organizat. Va rog sa ne
                contactati aici pentru detalii
              </p>
            </div>
          </div>

          <div
            className={activeTab === "backup" ? "" : "hidden"}
            role="tabpanel"
            aria-labelledby="backup-tab"
          >
            {/* Additional Information content */}
            {/* <img
              src={datacenterimg} // replace with your actual image path
              alt="Descriptive Alt Text"
              className="w-full object-cover" // adjust these classes as needed for your layout
            /> */}

            {/* Text content below the image */}
            <div className="text-content mt-4 px-4">
              {" "}
              {/* Add margin-top and padding as needed */}
              <h3 className="text-xl font-semibold text-slate-700 mb-2">
                Back-up profesional
              </h3>
              <p className="text-slate-400">
                HostingSpace foloseste pentru toate serviciile pe care le ofera
                o solutie de backup profesional. Aceasta solutie ne permite sa
                oferim clientilor backup-uri cu o vechime de pana la 30 zile (si
                in unele cazuri si mai mult).
              </p>
            </div>
          </div>

          <div
            className={activeTab === "domenii" ? "" : "hidden"}
            role="tabpanel"
            aria-labelledby="domenii-tab"
          >
            {/* Additional Information content */}
            <img
              src={datacenterimg} // replace with your actual image path
              alt="Descriptive Alt Text"
              className="w-full object-cover" // adjust these classes as needed for your layout
            />

            {/* Text content below the image */}
            <div className="text-content mt-4 px-4">
              {" "}
              {/* Add margin-top and padding as needed */}
              <h3 className="text-xl font-semibold text-slate-700 mt-4 mb-2">
                Parteneriat domenii.ro
              </h3>
              <p className="text-slate-400">
                HostingSpace este Registrar{" "}
                <a
                  href="https://rotld.ro/registrar-list/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-sky-500"
                >
                  {" "}
                  (partener autorizat ROTLD)
                </a>{" "}
                pentru domeniile .ro si in acelasi timp avem parteneriate cu
                unul dintre cei mai mari Registrari pentru alte tipuri de
                domenii (.com, .net, .org). Prin aceste parteneriate ne asiguram
                ca dvs beneficiati atat de cele mai bune preturi la
                inregistrarea domeniilor cat si de un serviciu de calitate.
              </p>
              <h3 className="text-xl font-semibold text-slate-700 mt-4 mb-2">
                Ce inseamna ca suntem Registrar pentru domeniile .ro
              </h3>
              <p className="text-slate-400">
                Registrarul este o companie sau o organizatie selectata si
                acreditata de registrul roman de domenii ROTLD sa ofere DIRECT
                servicii de inregistrare de domenii .ro. Registrarul trimite
                cererile pe care le primeste de la clientii sai (cei care doresc
                sa inregistreze un domeniu) direct REGISTRULUI ROTLD si nu
                lucreaza ca intermediar (reseller).
              </p>
              <h3 className="text-xl font-semibold text-slate-700 mt-4 mb-2">
                Cum va ajuta ca suntem Registrar?
              </h3>
              <p className="text-slate-400">
                Indiferent daca sunteti client final sau Reseller pentru domenii
                .ro oferta noastra vine in intampinarea dvs. Pentru ca suntem
                Registrar si lucram direct cu ROTLD va putem pune la dispozitie
                cele mai bune preturi pentru domeniile .ro, un timp mic de
                inregistrare (pentru plata cu cardul rezervarea unui domeniu se
                face instant), transparenta si, per total, un proces simplu si
                eficient de inregistrare domenii .ro
              </p>
              <h3 className="text-xl font-semibold text-slate-700 mt-4 mb-2">
                Cum pot inregistra un domeniu .ro daca sunt utilizator final?
              </h3>
              <p className="text-slate-400">
                Daca sunteti persoana fizica sau juridica si doriti sa cumparati
                un domeniu pentru dumneavoastra va rugam sa plasati direct
                comanda la noi pe site. Puteti face acest lucru la adresa:
                https://www.hostingspace.ro/client/domainchecker.php
              </p>
              <h3 className="text-xl font-semibold text-slate-700 mt-4 mb-2">
                Cum pot face un parteneriat cu HostingSpace daca sunt Reseller?
              </h3>
              <p className="text-slate-400">
                In cazul in care doriti sa revindeti domenii .ro va incurajam sa
                ne contactati prin formularul de pe site la sectiunea Vanzari.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TabWidget;
