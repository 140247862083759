export const heroContent = {
  GazduireStandard: {
    h1: "Gazduire Web in Romania: Rapiditate și Fiabilitate",
    h3: "Descoperă planuri de hosting web accesibile cu suport tehnic 24/7.",
    features: [
      { text: "2GB", detail: "memorie" },
      { text: "100GB", detail: "latime de banda" },
      { text: "25GB SSD", detail: "stocare" },
    ],
    price: "10",
    originalPrice: "20",
    contactLink: "/",
  },
  GazduirePremium: {
    h1: "Gazduire Premium pentru Afaceri și Proiecte Serioase",
    h3: "Beneficiază de viteza și securitatea superioară cu serviciile noastre de gazduire premium.",
    features: [
      { text: "4GB", detail: "memorie" },
      { text: "100GB", detail: "latime de banda" },
      { text: "25GB SSD", detail: "stocare" },
    ],
    price: "20",
    originalPrice: "40",
    contactLink: "/",
  },
  GazduireWordpress: {
    h1: "Gazduire WordPress Optimizată pentru Performanță",
    h3: "Lansează rapid și ușor site-uri WordPress cu gazduire optimizată și suport dedicat.",
    features: [
      { text: "4GB", detail: "memorie" },
      { text: "200GB", detail: "latime de banda" },
      { text: "50GB SSD", detail: "stocare" },
    ],
    price: "50",
    originalPrice: "100",
    contactLink: "/",
  },
  GazduireEcommerce: {
    h1: "Soluții de Gazduire E-commerce pentru Magazinul Tău Online",
    h3: "Crește-ți afacerea online cu platforme de gazduire e-commerce securizate și scalabile.",
    features: [
      { text: "6GB", detail: "memorie" },
      { text: "400GB", detail: "latime de banda" },
      { text: "50GB SSD", detail: "stocare" },
    ],
    price: "100",
    originalPrice: "200",
    contactLink: "/",
  },
  Reseller: {
    h1: "Gazduire Reseller: Începe-ți Propria Afacere de Hosting",
    h3: "Oferă servicii de gazduire web sub marca ta cu planurile noastre flexibile de reseller.",
    features: [
      { text: "8GB", detail: "memorie" },
      { text: "400GB", detail: "latime de banda" },
      { text: "100GB SSD", detail: "stocare" },
    ],
    price: "200",
    originalPrice: "400",
    contactLink: "/",
  },
};
