import React from "react";
import HostingServicesCard from "./HostingServicesCard";
import serviceData from "./serviceData";

const HostingServices = () => {
  return (
    <div className="container relative md:mt-24 mt-16">
      <div className="grid grid-cols-1 pb-8 text-center">
        <h2 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-medium">
          Serviciile Noastre de Gazduire
        </h2>
        <p className="text-slate-400 max-w-xl mx-auto">
          O gama completa de servicii pentru a asigura performanta si
          stabilitatea prezentei tale online.
        </p>
      </div>

      <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
        {serviceData.map((service, index) => (
          <HostingServicesCard
            key={index}
            title={service.title}
            description={service.description}
            IconComponent={service.icon}
          />
        ))}
      </div>
    </div>
  );
};

export default HostingServices;

