import React from "react";
import { Link } from "react-router-dom";
import { Facebook } from "react-feather";
import { FaXTwitter } from "react-icons/fa6";
import logoIcon from "../assets/images/logo-dark.png"; // Adjust the path as necessary
import logoANPC from "../assets/images/anpc-sal.png"; // Replace with actual path
import logoSOL from "../assets/images/anpc-sol.png"; // Replace with actual path

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer bg-slate-900 dark:bg-slate-950 relative text-gray-200">
      <div className="container relative">
        <div className="grid grid-cols-12">
          <div className="col-span-12">
            <div className="pt-[50px] px-0 border-t border-slate-800">
              <div className="text-center">
                <img src={logoIcon} className="block h-20 mx-auto" alt="" />
                <p className="max-w-xl mx-auto mt-8">
                  Splash your dream color Bring your home to lively Colors. We
                  make it a priority to offer flexible services to accommodate
                  your needs
                </p>
              </div>
            </div>
            <div className="py-[30px] px-0">
              <div className="grid grid-cols-1 gap-6 md:grid-cols-5 ">
                {/* Column 1 */}
                <div className="text-center md:text-left">
                  <h5 className="text-white font-bold">Gazduire</h5>
                  <ul className="list-none mt-4">
                    <li>
                      <Link to="/gazduire-web">Gazduire web</Link>
                    </li>
                    <li>
                      <Link to="/gazduire-premium">Gazduire premium</Link>
                    </li>
                    <li>
                      <Link to="/reseller-hosting">Reseller hosting</Link>
                    </li>
                    <li>
                      <Link to="/vps-self-managed">VPS self-managed</Link>
                    </li>
                  </ul>
                </div>

                {/* Column 2 */}
                <div className="text-center md:text-left">
                  <h5 className="text-white font-bold">Alte servicii</h5>
                  <ul className="list-none mt-4">
                    <li>
                      <Link to="/domenii-internet">Domenii internet</Link>
                    </li>
                    <li>
                      <Link to="/backup-r1soft">Backup R1Soft</Link>
                    </li>
                    <li>
                      <Link to="/certificate-ssl">Certificate SSL</Link>
                    </li>
                    <li>
                      <Link to="/parteneriate">Parteneriate</Link>
                    </li>
                  </ul>
                </div>

                {/* Column 3 */}
                <div className="text-center md:text-left">
                  <h5 className="text-white font-bold">Suport & Info</h5>
                  <ul className="list-none mt-4">
                    <li>
                      <Link to="/companie-despre-noi">
                        Companie / Despre noi
                      </Link>
                    </li>
                    <li>
                      <Link to="/centru-de-date">Centru de date</Link>
                    </li>
                    <li>
                      <Link to="/suport-tehnic-si-vanzari">
                        Suport tehnic & vanzari
                      </Link>
                    </li>
                    <li>
                      <Link to="/resurse-tehnice">Resurse tehnice</Link>
                    </li>
                  </ul>
                </div>

                {/* Column 4 */}
                <div className="text-center md:text-left">
                  <h5 className="text-white font-bold">Ajutor</h5>
                  <ul className="list-none mt-4">
                    <li>
                      <Link to="/detalii-conturi">Detalii conturi</Link>
                    </li>
                    <li>
                      <Link to="/intrebari-frecvente">Intrebari frecvente</Link>
                    </li>
                    <li>
                      <Link to="/lucrari-mentenanta">Lucrari mentenanta</Link>
                    </li>
                  </ul>
                </div>
                {/* Social Icons */}
                <ul className="list-none flex md:flex-row items-center justify-center md:justify-end gap-4">
                  <li className="inline">
                    <Link to="/facebook">
                      <Facebook className="inline-block h-8 w-8 mx-2 hover:text-sky-500" />
                    </Link>
                  </li>
                  <li className="inline">
                    <Link to="/twitter">
                      <FaXTwitter className="inline-block h-8 w-8 mx-2 hover:text-sky-500" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="py-[30px] px-0 border-t border-slate-800">
        <div className="container mx-auto">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="text-center md:text-left">
              <p className="text-sm">
                Toate drepturile rezervate. © {currentYear} Jordan Tech SRL
              </p>
              <p className="text-sm mt-2">
                Preturile nu contin TVA conform legislatiei in vigoare in
                Romania. PROTECTIA CONSUMATORILOR - A.N.P.C.
              </p>
              <p className="text-sm mt-2">
                Barajul Dunarii NR 8 Bucuresti, Sector 3 | RO16621403 |
                J40/11864/2004 Capital social: 200 RON
              </p>
            </div>
            <div className="flex flex-col	mt-4 md:mt-0">
              <a
                href="https://anpc.ro"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={logoANPC} alt="ANPC Logo" className="mx-2 w-40" />
              </a>
              <a
                href="https://solicitare-online.anpc.ro"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={logoSOL} alt="SOL Logo" className="mx-2 w-40" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
