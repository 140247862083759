// Assuming you're using React 17 or newer and your build system supports the new JSX Transform
import { CheckCircle } from "react-feather";
import { commonStandard } from "./Pachete";

const CommonTable = ({ commonData }) => {
  return (
    <div className="container relative md:mt-12 mt-8">
      <div className="grid grid-cols-1 pt-8 text-center">
        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-medium">
          {commonData.title}
        </h3>

        <div className="group px-2 py-6 relative overflow-hidden shadow-lg dark:shadow-gray-800 rounded-md h-fit">
          <div className="px-2 pb-6">
            <ul className="text-slate-400 columns-1 xs:columns-2 md2:columns-3">
              {commonData.features.map((feature, index) => (
                <li
                  key={index}
                  className="mb-1 flex items-center break-inside-avoid"
                >
                  <CheckCircle className="text-sky-500" size={20} />
                  <span className="ml-2">{feature}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonTable;
