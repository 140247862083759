
import React, { useState } from "react";
import NavItems from './navItems'; // Ensure the correct path
import ToggleButton from './ToggleButton'

const NavMenu = ({ isSticky }) => {
  return (
    <div className="md2:flex hidden">
      <NavItems isSticky={isSticky} />
    </div>
  );
};

export default NavMenu;
