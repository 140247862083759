import React, { useState } from "react";
import ToggleButton from "./ToggleButton";
import NavigationDropdown from "./NavigationDropdown";

const MobileToggle = ({ isSticky }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <>
      <ToggleButton isOpen={isOpen} toggleMenu={toggleMenu} isSticky={isSticky}/>
      <NavigationDropdown isOpen={isOpen} />
    </>
  );
};

export default MobileToggle;