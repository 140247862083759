// src/components/Faqs.jsx
import React, {useState} from "react";
import PropTypes from 'prop-types';
import image3 from '../../assets/images/3.png';
import { faqsData } from './faqsData';
import Accordion from './Accordion';

const Faqs = ({ pageId }) => {
    const filteredFaqs = faqsData.filter(faq => faq.pageId === 'generic' || faq.pageId === pageId);
    const [activeId, setActiveId] = useState(filteredFaqs.length > 0 ? filteredFaqs[0].id : ""); // Set initial active ID
  
    const toggleAccordion = (id) => {
      setActiveId(activeId === id ? "" : id); // Toggle the active accordion item
    };
  
    return (
      <div className="container relative md:mt-24 mt-16">
        <div className="grid grid-cols-1 pb-8 text-center">
          <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-medium">
            Questions regarding Dedicated Server ?
          </h3>
          <p className="text-slate-400 max-w-xl mx-auto">
            Create, collaborate, and turn your ideas into incredible products with
            the definitive platform for digital design.
          </p>
        </div>
  
        <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-8 gap-[30px]">
          <div className="lg:col-span-6">
            <div className="lg:me-8">
              <img src={image3} alt="FAQ" />
            </div>
          </div>
          <div className="lg:col-span-6">
            {filteredFaqs.map(faq => (
              <Accordion
                key={faq.id}
                faq={faq}
                isActive={faq.id === activeId}
                onToggle={toggleAccordion}
              />
            ))}
          </div>
        </div>
      </div>
    );
  };
  
  Faqs.propTypes = {
    pageId: PropTypes.string.isRequired
  };
  
  export default Faqs;