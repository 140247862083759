import React from "react";
import { Link } from "react-router-dom";
import { heroContent } from "./heroContent";

const HostingHero = ({ pageName }) => {
  const { h1, h3, features, price, originalPrice, contactLink } =
    heroContent[pageName];

  return (
    <div>
      <section className="relative lg:py-44 py-36 bg-gradient-to-br from-sky-500 to-blue-500">
        <div className="absolute inset-0 bg-[url('../../assets/images/bg/shape-1.png')] bg-center bg-cover"></div>
        <div className="container relative">
          <div className="grid lg:grid-cols-12 md:grid-cols-2 mt-12 items-center gap-[30px]">
            <div className="lg:col-span-8">
              <h1 className="text-4xl leading-normal text-white font-semibold mb-6">
                {h1}
              </h1>
              <h3 className="text-white/80 text-[17px] max-w-xl">{h3}</h3>

              <ul className="list-none text-white/80 mt-6">
                {features.map((feature, index) => (
                  <li key={index} className="mb-1 flex">
                    <i className="uil uil-check-circle text-white text-xl me-2"></i>
                    <span className="font-medium me-1">{feature.text}</span>{" "}
                    {feature.detail}
                  </li>
                ))}
              </ul>
            </div>

            <div className="lg:col-span-4">
              <div className="group p-[1px] relative overflow-hidden shadow-lg dark:shadow-gray-800 rounded-md text-center bg-gradient-to-tr from-sky-500 to-blue-700 h-fit">
                <div className="p-8 py-12 bg-white dark:bg-slate-900 rounded-md text">
                  <h6 className="font-medium mb-5 text-xl">
                    Special Offer Price
                  </h6>

                  <div className="d-flex justify-content-center font-semibold mt-3">
                    <span className="h6 text-slate-400 mb-0 mt-2">$</span>
                    <span className="price text-sky-500 text-5xl h1 mb-0">
                      {price}
                    </span>
                    <span className="h6 text-slate-400 align-self-end mb-1">
                      /mo
                    </span>
                  </div>

                  <p className="text-slate-400 mt-4">
                    Original Price{" "}
                    <span className="text-yellow-500">{originalPrice}</span>
                  </p>
                  <Link
                    to={contactLink}
                    className="inline-block px-8 py-2.5 text-[16px] font-medium tracking-wide bg-sky-500/10 hover:bg-sky-500 border border-sky-500/10 hover:border-sky-500 text-sky-500 hover:text-white focus:ring-[3px] focus:ring-sky-500 focus:ring-opacity-25 focus:outline-none rounded-md text-center align-middle transition-all duration-500 w-full mt-5"
                  >
                    Talk to us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
          <ul className="tracking-[0.5px] mb-0 inline-block">
            <li className="inline-block text-[15px] text-white/70 font-medium duration-500 ease-in-out hover:text-white">
              <Link to="/">Hostingspace</Link>
            </li>
            <li className="inline-block text-[15px] text-white/70 ltr:rotate-0 rtl:rotate-180">
              <i className="mdi mdi-chevron-right"></i>
            </li>
            <li className="inline-block text-[15px] font-medium duration-500 ease-in-out text-white">
              {pageName}
            </li>
          </ul>
        </div>
      </section>

      <div className="relative">
        <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
          <svg
            className="w-full h-auto"
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default HostingHero;
