import React from "react";
import TabWidget from "../components/TabWidget";
import AboutHeroBanner from "../components/AboutHeroBanner";
import siteMeta from "../components/meta/siteMeta";
import { fullTitle } from "../components/meta/siteTitle";
import { Helmet } from "react-helmet";

const DespreNoi = () => {
  return (
    <div>
      <Helmet>
        <title>{fullTitle(siteMeta.despreNoiMeta.title)}</title>
        <meta name="description" content={siteMeta.despreNoiMeta.description} />
      </Helmet>
      <AboutHeroBanner />
      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <TabWidget />
        </div>
      </section>
    </div>
  );
};

export default DespreNoi;
