import React from "react";
import HostingHero from "../components/hostingPages/HostingHero";
import DedicatedServers from "../components/hostingPages/DedicatedServers";
import PricingTable from "../components/pricing/PricingTable";
import SixCardFeatures from "../components/hostingPages/SixCardFeatures";
import Faqs from "../components/Faqs";
import { resellerData } from "../components/pricing/DataStructure";
import { Helmet } from "react-helmet";
import siteMeta from "../components/meta/siteMeta";
import { fullTitle } from "../components/meta/siteTitle";

const Reseller = () => {
  return (
    <>
      <Helmet>
        <title>{fullTitle(siteMeta.gazduireResellerMeta.title)}</title>
        <meta
          name="description"
          content={siteMeta.gazduireResellerMeta.description}
        />
      </Helmet>
      <HostingHero pageName="Reseller" />
      <section className="relative md:py-24 py-16">
        <PricingTable pricingData={resellerData} />

        <div className="md:mt-24 mt-16">
          <DedicatedServers />
        </div>
        <SixCardFeatures />
        <Faqs />
      </section>
    </>
  );
};

export default Reseller;
