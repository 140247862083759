import {
  ArrowUpCircle,
  CheckCircle,
  Globe,
  Headphones,
  Mail,
  Server,
  TrendingUp,
  Zap,
} from "react-feather";

const serviceData = [
  {
    title: "Web Hosting",
    description:
      "Gazduire web optimizata pentru viteza si securitatea site-ului tau.",
    icon: Server,
  },
  {
    title: "Domenii",
    description:
      "Alege un nume de domeniu memorabil si relevant pentru brandul tau.",
    icon: Globe,
  },
  {
    title: "Emailuri",
    description:
      "Solutii de email profesionale pentru o comunicare eficienta si sigura.",
    icon: Mail,
  },
  {
    title: "Suport Tehnic",
    description:
      "Asistenta tehnica dedicata pentru toate intrebarile si problemele tale.",
    icon: Headphones,
  },
  {
    title: "Performanta Inalta",
    description:
      "Hosting de inalta performanta pentru incarcare rapida si functionare fara intreruperi.",
    icon: Zap,
  },
  {
    title: "Fiabilitate",
    description:
      "Uptime excelent si tehnologie de ultima ora pentru a mentine site-ul tau online.",
    icon: CheckCircle,
  },
  {
    title: "Scalabilitate",
    description:
      "Upgrade-uri facile care cresc odata cu necesitatile afacerii tale.",
    icon: TrendingUp,
  },
  {
    title: "Upgrade Usor",
    description:
      "Flexibilitatea de a-ti extinde serviciile cand afacerea ta necesita mai mult.",
    icon: ArrowUpCircle,
  },
];

export default serviceData;
