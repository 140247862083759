// src/components/Accordion.jsx
import React from "react";
import PropTypes from 'prop-types';

const Accordion = ({ faq, isActive, onToggle }) => {
  return (
    <div className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden" key={faq.id}>
      <h2 className="text-base font-semibold" id={`accordion-collapse-heading-${faq.id}`}>
        <button
          type="button"
          className={`flex justify-between items-center p-5 w-full font-medium text-start ${isActive ? 'text-sky-500' : 'text-slate-800'}`}
          onClick={() => onToggle(faq.id)}
          aria-expanded={isActive}
          aria-controls={`accordion-collapse-body-${faq.id}`}
        >
          <span className="text-[17px]">{faq.question}</span>
          <svg
            className={`w-4 h-4 shrink-0 transform transition-transform duration-500 ease-in-out ${isActive ? 'rotate-180' : ''}`}
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
          </svg>
        </button>
      </h2>
      <div
        id={`accordion-collapse-body-${faq.id}`}
        className={`${isActive ? '' : 'hidden'}`}
        aria-labelledby={`accordion-collapse-heading-${faq.id}`}
      >
        <div className="p-5">
          <p className="text-slate-400 dark:text-gray-400">{faq.answer}</p>
        </div>
      </div>
    </div>
  );
};

Accordion.propTypes = {
  faq: PropTypes.shape({
    id: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
    answer: PropTypes.string.isRequired
  }).isRequired,
  isActive: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired
};

export default Accordion;
