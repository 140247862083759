import React from 'react';
import {Mail, Key, HelpCircle } from 'react-feather';

const TagLine = () => {
  return (
    <div className="tagline bg-gradient-to-tr from-sky-500 to-blue-700 absolute w-full z-[99] text-sm py-3 px-0">
      <div className="container relative">
        <div className="grid grid-cols-1">
          <div className="flex justify-between">
            <ul className="list-none">
              
              <li className="inline-flex items-center ps-2">
                <Mail className="h-4 text-white me-1" />
                <a href="mailto:vanzari@hostingspace.ro" className="text-white/70 hover:text-white transition-all duration-500">vanzari@hostingspace.ro</a>
              </li>
              <li className="inline-flex items-center ps-2">
                <Mail className="h-4 text-white me-1" />
                <a href="mailto:tehnic@hostingspace.ro" className="text-white/70 hover:text-white transition-all duration-500">tehnic@hostingspace.ro</a>
              </li>
            </ul>

            <ul className="list-none">
              <li className="inline-flex items-center">
                <Key className="h-4 text-white me-1" />
                <a href="https://www.hostingspace.ro/client/index.php?rp=/login" className="text-white/70 hover:text-white transition-all duration-500">Login</a>
              </li>
              <li className="inline-flex items-center sm:ps-2">
                <HelpCircle className="h-4 text-white me-1" />
                <a href="https://www.hostingspace.ro/client/submitticket.php" className="text-white/70 hover:text-white transition-all duration-500">Support</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TagLine;
