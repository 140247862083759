import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import client1 from '../assets/images/client/01.jpg'
import client2 from '../assets/images/client/02.jpg'
import client3 from '../assets/images/client/03.jpg'
import client4 from '../assets/images/client/04.jpg'

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const slidesData = [
  {
    id: 1,
    name: "Thomas Israel",
    title: "C.E.O",
    review:
      "I didn't know a thing about icon design until I read this book. Now I can create any icon I need in no time. Great resource!",
    rating: 5,
    imgSrc: {client1},
  },
  {
    id: 2,
    name: "Jane Doe",
    title: "Marketing Director",
    review:
      "This book has revolutionized the way I approach design. The tips are practical and easy to implement. Highly recommended!",
    rating: 4,
    imgSrc: {client2},
  },
  {
    id: 3,
    name: "John Smith",
    title: "Lead Developer",
    review:
      "A must-read for anyone in the design industry. The insights and techniques are invaluable for my daily work.",
    rating: 5,
    imgSrc: {client3},
  },

  {id: 4,
  name: "John Smith",
  title: "Lead Developer",
  review:
    "A must-read for anyone in the design industry. The insights and techniques are invaluable for my daily work.",
  rating: 5,
  imgSrc: {client4},
},
];

const MySlider = () => (
  <div className="container relative md:mt-24 mt-16">
    <div className="grid grid-cols-1 relative">
      <Slider {...sliderSettings}>
        {slidesData.map((slide) => (
          <div key={slide.id} className="tiny-slide">
            <div className="rounded-md bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 p-6 m-2">
              <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                <img
                  src={slide.imgSrc}
                  className="h-16 w-16 rounded-full shadow dark:shadow-gray-800"
                  alt={slide.name}
                />
                <div className="ps-4">
                  <a
                    href=""
                    className="text-lg h6 hover:text-sky-500 duration-500 ease-in-out"
                  >
                    {slide.name}
                  </a>
                  <p className="text-slate-400">{slide.title}</p>
                </div>
              </div>
              <div className="mt-6">
                <p className="text-slate-400">{slide.review}</p>
                <ul className="list-none mb-0 text-amber-400 mt-2">
                  {[...Array(slide.rating)].map((_, i) => (
                    <li key={i} className="inline">
                      <i className="mdi mdi-star"></i>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  </div>
);

export default MySlider;
