import React from "react";
import { Link } from "react-router-dom";
import { CheckCircle } from "react-feather";
import { commonStandard } from "./Pachete";

const PricingTable = ({ pricingData }) => {
  return (
    <div className="container relative">
  <div className="grid grid-cols-1 pb-8 text-center">
    <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-medium">
      Descopera Ofertele Noastre de Hosting
    </h3>
    <p className="text-slate-400 max-w-xl mx-auto">
      Alege planul perfect de hosting pentru nevoile tale si beneficiaza de suportul nostru dedicat, garantand succesul proiectului tau online.
    </p>
  </div>

  <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-[30px] mt-8">
    {pricingData.map((plan, index) => (
      <div
        key={index}
        className={`group px-2 py-6 relative overflow-hidden shadow-lg dark:shadow-gray-800 rounded-md h-fit`}
      >
        {plan.popular && (
          <div className="absolute top-0 inset-x-0 bg-gradient-to-tr from-sky-500 to-blue-700 text-white py-2 px-6 h6 text-lg font-medium text-center">
            Popular
          </div>
        )}
        <div className={`${plan.popular ? "pt-8" : ""} px-2 pb-6`}>
          <h6 className="font-medium mb-5 text-center text-xl">
            {plan.title}
          </h6>
          <div className="flex justify-center items-center mb-5">
            <span className="text-lg font-medium">LEI</span>
            <span className="price text-5xl h6 font-semibold mb-0">
              {plan.price}
            </span>
            <span className="text-lg font-medium self-end mb-1">/an</span>
          </div>
          <ul className="text-slate-400">
            {plan.features.map((feature, featureIndex) => (
              <li key={featureIndex} className="mb-1 flex items-center">
                <CheckCircle className="text-sky-500" size={20} />
                <span className="ml-2">{feature}</span>
              </li>
            ))}
          </ul>
          <Link
            to={plan.link}
            className={`inline-block px-8 py-2.5 text-[16px] font-medium tracking-wide text-center align-middle transition-all duration-500 w-full mt-5 bg-transparent hover:bg-sky-500 border border-gray-100 dark:border-gray-800 hover:border-sky-500 dark:hover:border-sky-500 text-slate-900 dark:text-white hover:text-white
          focus:ring-[3px] focus:ring-sky-500 focus:ring-opacity-25 focus:outline-none rounded-md`}
          >
            {plan.popular ? "Cumpara" : "Alege"}
          </Link>
          <p className="text-sm text-slate-400 mt-1.5">
            <span className="text-red-600">*</span>TVA Inclus
          </p>
        </div>
      </div>
    ))}
  </div>

  
</div>

  );
};

export default PricingTable;
