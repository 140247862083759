import React from "react";
import { Hexagon } from "react-feather";

const HostingServicesCard = ({ title, description, IconComponent }) => {
  return (
    <div className="group relative p-6 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-sky-500 dark:hover:bg-sky-500 transition-all duration-500 ease-in-out rounded-md bg-white dark:bg-slate-900 overflow-hidden">
      <div className="relative overflow-hidden text-transparent -m-3">
        <Hexagon className="h-24 w-24 fill-sky-500/[0.07] group-hover:fill-white/20" />
        <div className="absolute h-24 w-24 top-0 left-0 flex justify-center items-center">
          <IconComponent className="text-sky-500 group-hover:text-white transition-all duration-500 ease-in-out text-3xl" />
        </div>
      </div>
      <div className="mt-6">
        <h5>
          <a
            href="#"
            className="text-lg font-medium group-hover:text-white transition-all duration-500 ease-in-out"
          >
            {title}
          </a>
        </h5>
        <p className="text-slate-400 group-hover:text-white/50 transition-all duration-500 ease-in-out mt-3">
          {description}
        </p>
      </div>
    </div>
  );
};

export default HostingServicesCard;
