import React from 'react';
import mapBackground from '../assets/images/map.png'; // Adjust the path as per your project structure

const TrustedUsers
 = () => {
  return (
    <div className="container relative md:mt-24 mt-16 lg:pt-24 pt-16">
      <div 
        style={{ backgroundImage: `url(${mapBackground})` }} 
        className="absolute inset-0 opacity-25 dark:opacity-50 bg-no-repeat bg-center bg-cover"
      ></div>

      <div className="relative grid grid-cols-1 pb-8 text-center z-1">
        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-medium">
          Trusted by more than 10K users
        </h3>
        <p className="text-slate-400 max-w-xl mx-auto">
          Create, collaborate, and turn your ideas into incredible products with the definitive platform for digital design.
        </p>
      </div>

      <div className="relative grid md:grid-cols-3 grid-cols-1 items-center mt-8 gap-[30px] z-1">
        {/* Counter Box 1 */}
        <div className="counter-box text-center">
          <h1 className="lg:text-4xl text-3xl font-semibold mb-2 text-slate-400 dark:text-white">
            <span className="counter-value" data-target="1548">1010</span>+
          </h1>
          <h5 className="counter-head text-lg font-medium">Properties Sell</h5>
        </div>

        {/* Counter Box 2 */}
        <div className="counter-box text-center">
          <h1 className="lg:text-4xl text-3xl font-semibold mb-2 text-slate-400 dark:text-white">
            <span className="counter-value" data-target="25">2</span>+
          </h1>
          <h5 className="counter-head text-lg font-medium">Award Gained</h5>
        </div>

        {/* Counter Box 3 */}
        <div className="counter-box text-center">
          <h1 className="lg:text-4xl text-3xl font-semibold mb-2 text-slate-400 dark:text-white">
            <span className="counter-value" data-target="9">0</span>+
          </h1>
          <h5 className="counter-head text-lg font-medium">Years Experience</h5>
        </div>
      </div>
    </div>
  );
};

export default TrustedUsers
;
