import React, { useState, useEffect } from "react";
import Logo from "./navbars/Logo";
import NavMenu from "./navbars/NavMenu";
import MobileToggle from "./navbars/MobileToggle";
import TagLine from "./navbars/TagLine";

const Navigation = () => {
  const [isSticky, setIsSticky] = useState(false);

  const handleScroll = () => {
    if (
      document.body.scrollTop >= 50 ||
      document.documentElement.scrollTop >= 50
    ) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <TagLine />
      <nav
        id="topnav"
        className={`defaultscroll fixed start-0 end-0 w-screen z-999 py-5 transition-all duration-500 ease-in-out ${
          isSticky ? "nav-sticky bg-white top-0 shadow-sm" : "top-[47px] bg-transparent shadow-none"
        }`}
      >
        <div className="flex container relative justify-between">
          <Logo isSticky={isSticky} />
          {/* Include MobileToggle which now has the responsibility of showing/hiding the menu */}
          <MobileToggle isSticky={isSticky} />
          {/* NavMenu now is only responsible for showing navigation items */}
          <NavMenu isSticky={isSticky} />
        </div>
      </nav>
    </>
  );
};

export default Navigation;
