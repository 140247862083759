export const faqsData = [
    {
      id: 'faq1',
      question: 'How does it work?',
      answer: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.',
      pageId: 'generic'
    },
    {
      id: 'faq2',
      question: 'Do I need a designer?',
      answer: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.',
      pageId: 'generic'
    },
    {
      id: 'faq3',
      question: 'How do I pay?',
      answer: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.',
      pageId: 'generic'
    },
    {
      id: 'premium-faq1',
      question: 'What is specific to the premium?',
      answer: 'This is a premium specific FAQ.',
      pageId: 'premium'
    },
    {
        id: 'ecommerce-faq1',
        question: 'What is specific to the ecommerce?',
        answer: 'This is a ecommerce specific FAQ.',
        pageId: 'ecommerce'
      }

  ];