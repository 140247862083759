import React from "react";
import img1 from "../assets/images/1.png";
import { Link } from "react-router-dom";

const HeroSection = () => {
  return (
    <>
      <div className="absolute inset-0 bg-[url('../../assets/images/bg/shape-1.png')] bg-center bg-cover opacity-5"></div>
      <div className="container relative">
        <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-10 gap-[30px]">
          <div className="md:col-span-7">
            <div className="me-6">
              <h1 className="font-semibold lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 text-white">
                Gazduire Web Premium pentru Afaceri Moderne
              </h1>
              <h3 className="text-white/70 text-lg max-w-xl">
                Tot ce ai nevoie pentru prezenta ta online: domenii, gazduire
                web stabila, si suport email pentru afacerea ta.
              </h3>

              <div className="mt-6">
                <Link
                  to="/gazduire-premium"
                  className="inline-block px-8 py-2.5 text-[16px] font-medium tracking-wide bg-yellow-500 hover:bg-yellow-600 border border-yellow-500 hover:border-yellow-600 text-white focus:ring-[3px] focus:ring-yellow-500 focus:ring-opacity-25 focus:outline-none rounded-md text-center align-middle transition-all duration-500"
                >
                  Incepe Acum
                </Link>
              </div>
            </div>
          </div>

          <div className="md:col-span-5">
            <img src={img1} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
