import React from "react";

const ToggleButton = ({ isOpen, toggleMenu, isSticky }) => {
  // Set the appropriate color classes based on the isSticky state
  const colorClass = isSticky ? "bg-slate-900" : "bg-white";

  return (
    <button
      className={`absolute top-4 right-4 z-30 md2:hidden ${
        isOpen ? "open" : ""
      }`}
      onClick={toggleMenu}
      aria-label="Toggle navigation menu"
    >
      <div className="space-y-1">
        <span
          className={`block h-0.5 w-5 transform transition duration-300 ease-in-out ${colorClass} ${
            isOpen ? "rotate-45 translate-y-1.5" : ""
          }`}
        ></span>
        <span
          className={`block h-0.5 w-5 transition duration-300 ease-in-out ${colorClass} ${
            isOpen ? "opacity-0" : ""
          }`}
        ></span>
        <span
          className={`block h-0.5 w-5 transform transition duration-300 ease-in-out ${colorClass} ${
            isOpen ? "-rotate-45 -translate-y-1.5" : ""
          }`}
        ></span>
      </div>
    </button>
  );
};

export default ToggleButton;
