import { g10, g25, g50, g100} from "./Pachete";
import { p10, p25, p50, p100} from "./Pachete";
import { wp10, wp25, wp50, wp100} from "./Pachete";
import { e10, e25, e50, e100} from "./Pachete";
import { rs10, rs25, rs50, rs100} from "./Pachete";

export const gazduireHomepageData = [g10, p25, e50, wp100,];

export const gazduireStandardData = [g10, g25, g50, g100,];

export const gazduirePremiumData = [p10, p25, p50, p100,];

export const gazduireEcommerceData = [e10, e25, e50, e100,];
export const gazduireWordpressData = [wp10, wp25, wp50, wp100,];
export const resellerData = [rs10, rs25, rs50, rs100,];
